<template>
  <div class="lootbox-balance-wrapper">
    <div class="lootbox-balance">
      <div class="lootbox-balance-title">
        Your Wallet Holdings
      </div>
      <div class="lootbox-balance-items">
        <div class="lootbox-balance-item">
          <IWallet
            width="25"
            class="lootbox-balance-item__icon"
          />
          <span class="lootbox-balance-item__title">Balance</span>

          <span class="lootbox-balance-item__value">{{ ethBalance }} $ETH</span>
        </div>
        <div class="lootbox-balance-item">
          <IOnChain
            class="lootbox-balance-item__icon"
            width="25"
          />
          <span class="lootbox-balance-item__title">On-Chain</span>
          <div class="lootbox-balance-item__value">
            <ANumberAnimate
              :comp="'span'"
              :num="methBalance"
            />&nbsp;METHANE
          </div>
        </div>
        <div class="lootbox-balance-item">
          <IOffChain
            class="lootbox-balance-item__icon"
            width="25"
          />
          <span class="lootbox-balance-item__title">Meth Bank</span>
          <div class="lootbox-balance-item__value">
            {{ methBankBalance }}&nbsp;METHANE
          </div>
        </div>
      </div>
    </div>
    <a
      href="#"
      class="lootbox-balance-button"
      @click.prevent="buyMeth"
    >Buy Methane</a>
  </div>
</template>
<script>
import { computed, defineComponent } from "vue"
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline"
import { useWeb3Store } from "@/store/web3"
import { useModal } from "@/composables/useModal"
import { ModalName } from "@/components/Modal/modal-name"
import { connectWallet } from "@/web3"
import ANumberAnimate from "@/components/ANumberAnimate/ANumberAnimate.vue"
import { useMethBankStore } from "@/store/meth-bank"
import { formatNumberSpaces } from "@/helpers/format-number-spaces"
import IOnChain from "@/assets/icons/on-chain.svg?inline"
import IOffChain from "@/assets/icons/off-chain.svg?inline"
export default defineComponent({
  components: {
    IWallet, IOnChain, IOffChain, ANumberAnimate
  },
  setup() {
    const web3Store = useWeb3Store()
    const methBalance = computed(() => +web3Store.meth)
    const methBankBalance = computed(() => formatNumberSpaces(useMethBankStore().balance))
    const ethBalance = computed(() => web3Store.balance.toFixed(4))
    const buyMeth = async () => {
      if (!web3Store.isConnected) {
        const done = await connectWallet()
        if (!done) return
      }
      const { open, bus } = useModal()
      open({ name: ModalName.BuyMethModal })
      const onClose = () => {
        bus.off('close', onClose)
        useWeb3Store().update();
      }
      bus.on('close', onClose)
    }
    return { methBalance, ethBalance, methBankBalance, buyMeth }
  }
})
</script>
<style lang="scss">
.lootbox-balance {
  border: 1px solid var(--ape-constructor-gray);
  font-family: var(--font-oxanium);
  padding: 12px 12px;
  border-radius: 10px;
  margin-right: 15px;
  text-align: left;
  flex: 1;
  &-wrapper {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  &-items {
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
  }
  &-title {
    opacity: 0.5;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    font-size: 16px;
    &__icon {
      color: var(--ape-constructor-gray);
      margin-right: 5px;
    }
    &__title {
      text-transform: uppercase;
      font-size: 14px;
      opacity: 0.5;
      margin-right: 5px;
    }
    &-meth {
    }
  }
  &-button {
    background-color: var(--primary);
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    padding: 10px;
    border-radius: 32px;
    width: 180px;
    text-align: center;
    transition: 0.2s;
    margin-left: auto;
    &:hover {
      filter: brightness(0.8);
    }
  }
  @media screen and (max-width: 992px) {
    margin-right: 0;
    &-wrapper {
      flex-direction: column;
    }
    &-button {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    border: none;
    padding: none;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    &-items {
      border: 1px solid var(--ape-constructor-gray);
      padding: 10px;
      border-radius: 10px;
    }
    &-title {
      margin-right: 0;
      margin-bottom: 5px;
      font-size: 14px;
    }
    &-item {
      font-size: 14px;
      white-space: nowrap;
    }
    &-button {
      margin-left: 15px;
      width: 140px;
      font-size: 14px;
      padding: 12px 0;
    }
  }
  @media screen and (max-width: 600px) {
    &-wrapper {
      margin-bottom: 30px;
    }
    &-title {
      margin-bottom: 5px;
      font-size: 14px;
    }
    &-items {
      width: 100%;
      border-radius: 10px;
      flex-wrap: wrap;
    }
    &-item {
      flex: 1;
      margin-right: 10px;
      &:last-child {
        border-bottom: none;
      }
    }
    &-button {
      margin-left: 0;
      margin-top: 5px;
      width: 100%;
      padding: 7px 10px;
      max-width: 130px;
    }
  }
}
</style>