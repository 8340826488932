<template>
  <div class="lootbox-ape-slider">
    <Swiper
      :modules="modules"
      :navigation="{ nextEl: '.lootbox-ape-slider-arrow-right', prevEl: '.lootbox-ape-slider-arrow-left' }"
      :space-between="15"
      @init="onSliderInit"
      @slideChange="onSlideChange"
    >
      <SwiperSlide v-for="item in items" :key="item.id">
        <div class="lootbox-ape-slider-item">
          <CollectionCardImage :image="item.image" :traits="item.traits" />
          <CollectionCardHeader
            :id="item.originalId"
            :guild="item.guild"
            :rank="item.type === TokenType.APE ? item.rank : undefined"
          />
          <GodBullPowerProgress :show-tooltip="false" :progress="item.power" />
          <CollectionCardActions
            :items="[{ name: 'Customize This Ape', event: 'customize', disabled: item.disableCustomization }]"
            @customize="customizeApe"
          />
        </div>
      </SwiperSlide>
    </Swiper>
    <a
      href="javascript:void(0)"
      class="lootbox-ape-slider-arrow-left ape-constructor-arrow ape-constructor-arrow-left"
    >
      <IArrowRight width="14" />
    </a>
    <a
      href="javascript:void(0)"
      class="lootbox-ape-slider-arrow-right ape-constructor-arrow ape-constructor-arrow-right"
    >
      <IArrowRight width="14" />
    </a>
  </div>
</template>
<script>
import { computed, defineComponent, ref } from "vue"
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Navigation, } from 'swiper';
import 'swiper/swiper-bundle.css';
import IArrowRight from "@/assets/icons/ape-constructor/ape-constructor-arrow-right.svg?inline"
import CollectionCardImage from "@/components/Collection/CollectionCard/CollectionCardImage.vue";
import GodBullPowerProgress from "@/components/Collection/GodBullPowerProgress.vue";
import CollectionCardHeader from "@/components/Collection/CollectionCard/CollectionCardHeader.vue";
import CollectionCardActions from "@/components/Collection/CollectionCard/CollectionCardActions.vue";
import { useRouter } from "vue-router";
import { TokenType } from "@/config/token-types";



export default defineComponent({

  props: {
    apeId: Number,
    items: {
      type: Array,
      default: () => []
    }
  },
  components: {
    CollectionCardImage,
    CollectionCardHeader,
    GodBullPowerProgress,
    IArrowRight, Swiper,
    SwiperSlide,
    CollectionCardActions

  },
  setup(props, { emit }) {

    const onSliderInit = (swiper) => {
      if (props.apeId) {
        const activeIdx = props.items.findIndex(item => item.id == props.apeId)
        if (activeIdx < 0) return
        swiper.slideTo(activeIdx)
      }

    }
    const onSlideChange = (e) => {
      emit('update:apeId', props.items[e.realIndex].id)
    }
    const router = useRouter()
    const customizeApe = () => {
      router.push({ name: "ApeConfigurator", params: { id: props.apeId } })
    }
    const modules = [Navigation]

    return { modules, TokenType, customizeApe, onSliderInit, onSlideChange }
  }
})
</script>
<style lang="scss">
.lootbox-ape-slider {
  position: relative;
  width: 100%;
  /*background: linear-gradient(180deg, #17111f 0%, #22080a 116.24%); */
  box-sizing: border-box;
  &::before,
  &::after {
    display: none;
    content: "";
    background-color: var(--ape-constructor-gray);
    height: 1px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &::before {
    width: 90%;
    top: 5px;
  }
  &::after {
    width: 85%;
  }
  &-item {
    padding: 3px;
    &__image {
      width: 100%;
      border: 1px solid var(--ape-constructor-gray);
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    &__id {
      text-align: center;
      margin-top: 3px;
      font-size: 18px;
    }
  }
  &-arrow {
    &-left,
    &-right {
      width: 40px;
      height: 40px;

      svg {
        width: 10px;
      }
    }
    &-left {
      left: 0;
    }
    &-right {
      right: 0;
    }
  }
}
</style>