<template>
  <div class="lootbox-boxes-row" :class="type">
    <a href="#" class="lootbox-boxes-row-scroll-btn" @click.prevent="$emit('openScroll')">
      <IList width="24" />
      {{ scrollButton }}
    </a>
    <div class="lootbox-boxes-row-name">{{ name }}</div>

    <div class="lootbox-boxes-row-items">
      <LootBoxBoxesItem
        v-for="(item, idx) in items"
        :key="idx"
        :item="item"
        :buyingItem="buyingItem"
        :type="type"
        @click.prevent="$emit('buy', item)"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue"
import IList from "@/assets/icons/ape-constructor/ape-constructor-lootbox-scroll.svg?inline"
import LootBoxBoxesItem from "./LootBoxBoxesItem.vue"
export default defineComponent({
  components: {
    IList,
    LootBoxBoxesItem,
  },
  props: {
    scrollButton: String,
    name: String,
    buyingItem: {
      type: Object,
      default: () => ({})
    },
    type: String,
    items: {
      type: Array,
      default: () => { }
    }
  },
  setup() {
    const breakpoints = {
      800: {
        slidesPerView: 3,
        spaceBetween: 24

      },
      576: {
        spaceBetween: 24
      }
    }


    return { breakpoints, }
  }
})
</script>
<style lang="scss">
.lootbox-boxes {
  &-row {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    &.legendary & {
      &-name {
        background-color: var(--rarity-legendary);
      }
    }
    &.epic & {
      &-name {
        background-color: var(--rarity-epic);
      }
    }
    &-items {
      display: flex;
    }
    &-name {
      text-transform: uppercase;
      padding: 8px;
      width: 100%;
      text-align: center;
      margin-bottom: 1px;
      box-sizing: border-box;
    }
    &-scroll-btn {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 14px;
      svg {
        margin-right: 8px;
      }
    }
  }

  &-item {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #1f1f1f;
    border: 1px solid var(--ape-constructor-gray);
    padding-bottom: 12px;
    box-sizing: border-box;
    color: #fff;
    text-decoration: none;
    flex: 1;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }

    &__loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__image {
      margin-bottom: 8px;
      width: 100%;
      img {
        width: 100%;
        max-width: 100%;
      }
    }
    &__price {
      opacity: 0.8;
      font-weight: normal;
    }
    &__buy-card {
      &-del {
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4px 0;
        span {
          margin: 0 4px;
        }
        &::before,
        &::after {
          content: "";
          flex: 1;
          height: 1px;
          background-color: #fff;
        }
      }
      &-price {
        text-align: center;
        margin-bottom: 5px;
      }
      &-button {
        border: 1px solid var(--border);
        padding: 5px 10px;
        box-sizing: border-box;
        border-radius: 5px;
        color: #fff;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-right: 3px;
        }
      }
      &-crossmint {
        &-bg {
          position: absolute;
          background-color: #000;
          opacity: 0.4;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          filter: blur(5px);
        }
        &-btn {
          position: relative;
          z-index: 5;
          &::part(button) {
            background-color: #000613 !important;
          }
        }
      }
    }
    &__count {
      font-style: italic;
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 8px;
    }
  }
  @media screen and (max-width: 768px) {
    &-item {
      &__count {
        font-size: 18px;
      }
      &__buy-card {
        padding: 0 5px;
        &-del {
          font-size: 12px;
        }
        &-button {
          font-size: 14px;
          text-align: center;
        }
        &-crossmint {
          &-btn {
            &::part(button) {
              img {
                width: 10px !important;
                margin-right: 5px;
              }
              font-size: 10px !important;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 576px) {
    &-item {
      padding-bottom: 8px;
      &__image {
        margin-bottom: 8px;
      }
      &__count {
        font-size: 16px;
        margin-bottom: 3px;
      }
      &__price {
        font-size: 14px;
      }
      &__loading {
        img {
          max-width: 60%;
        }
      }
    }
    &-row-scroll-btn {
      svg {
        width: 16px;
      }
      font-size: 14px;
    }
  }
  @media screen and (max-width: 420px) {
    &-item {
      &__count {
        font-size: 12px;
      }
      &__price {
        font-size: 12px;
      }
      &__buy-card {
        &-button {
          font-size: 12px;
          padding-left: 3px;
          padding-right: 3px;
          svg {
            display: none;
          }
        }
      }
    }
  }
}
</style>