<template>
    <div class="lootbox-boxes-item" @click.prevent="$emit('buy', item)">
        <div class="lootbox-boxes-item__image">
            <img width="210" :src="item.image" :alt="item.price" />
        </div>
        <div
            href="#"
            class="lootbox-boxes-item__count"
        >{{ item.amount }} Loot Box{{ item.amount > 1 ? 'es' : '' }}</div>
        <div class="lootbox-boxes-item__price">{{ item.price }}</div>
        <div class="lootbox-boxes-item__buy-card" v-if="false" @click.stop>
            <div class="lootbox-boxes-item__buy-card-del">
                <span>OR</span>
            </div>
            <div
                class="lootbox-boxes-item__price lootbox-boxes-item__buy-card-price"
            >{{ item.usdPrice }}</div>

            <div class="lootbox-boxes-item__buy-card-crossmint" v-if="showCardButton">
                <crossmint-pay-button
                    :collectionId.prop="collectionId"
                    :projectId.prop="projectId"
                    :mintConfig.prop="mintConfig"
                    :environment.prop="environment"
                    :mintTo.prop="walletAddress"
                    :successCallbackURL.prop="successUrl"
                    class="lootbox-boxes-item__buy-card-crossmint-btn"
                />
            </div>
        </div>
        <div
            v-if="buyingItem && buyingItem.amount === item.amount"
            class="lootbox-boxes-item__loading"
        >
            <img src="@/assets/img/spinner-clear.svg" class="loader" width="50" />
        </div>
    </div>
</template>
<script>
import { useApesStorageApi } from "@/api/useApesStorageApi";
import ICard from "@/assets/icons/credit-card.svg?inline"
import { Config } from "@/config";
import { useWeb3Store } from "@/store/web3";
import { computed, defineComponent, ref } from "vue-demi";
import CrossmintPayButton from '@crossmint/client-sdk-vanilla-ui'
import Web3 from "web3";
import { useRouter } from "vue-router";
export default defineComponent({
    props: { item: Object, buyingItem: Object, type: String, },
    components: { CrossmintPayButton, ICard },
    setup(props) {
        const cardInfo = computed(() => props.item.crossmint || {})
        const showCardButton = computed(() => Object.keys(cardInfo.value).length > 0)
        const collectionId = computed(() => Config.crossMint.lootBox.collectionId)
        const projectId = computed(() => Config.crossMint.projectId)
        const router = useRouter()
        const successUrl = computed(() => {
            return window.location.origin + router.resolve({ name: "CrossMintSuccess" }).fullPath
        })
        const resetCardButton = () => {
        }
        const mintConfig = computed(() => {
            const price = Web3.utils.fromWei(cardInfo.value.price ? cardInfo.value.price + '' : '0').toString()
            const conf = {
                "type": "erc-721",
                "totalPrice": price, // API response but in ETH
                "amount": props.item.amount, // amount of boxes
                "boxType": props.type === "epic" ? 1 : 2, // 1 epic, 2 legendary, // 1 epic, 2 legendary
                "_price": cardInfo.value.price, // API Response
                "timeOut": cardInfo.value.timeOut, // API Response
                "signature": cardInfo.value.signature, // API Response
                "quantity": 1 // always 1
            }
            console.log(conf)
            return conf
        })
        const environment = computed(() => Config.mode === 'production' ? '' : 'staging')
        const walletAddress = computed(() => useWeb3Store().walletAddress)
        const buyWithCard = async () => {
            const res = await useApesStorageApi({
                toast: { error: true },
            }).exec({
                url: "/lootBox/buySpecial",
                params: {
                    boxType: props.type,
                    recipient: useWeb3Store().walletAddress,
                    amount: props.item.amount,
                    network: Config.network.name,
                },
            });
            if (!res) return
            console.log(res)
            cardInfo.value = res
        }
        return { environment, walletAddress, buyWithCard, mintConfig, resetCardButton, successUrl, projectId, collectionId, showCardButton, cardInfo }
    }
})
</script>
<style lang="scss"></style>