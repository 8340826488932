<template>
  <div class="lootbox-boxes-balance-item">
    <div class="lootbox-boxes-balance-item-row">
      <div class="lootbox-boxes-balance-item-image">
        <img :src="image" alt="container" width="120" />
      </div>
      <div class="lootbox-boxes-balance-item-content">
        <div
          class="lootbox-boxes-balance-item-text"
        >{{ text }}</div>
        <ACounter v-model:value="amountModel" class="lootbox-boxes-balance-item-counter" />
        <div class="lootbox-boxes-balance-item-price-wrapper" v-if="price">
          <div class="lootbox-boxes-balance-item-price-cross">x</div>
          <div class="lootbox-boxes-balance-item-price">{{ price }} METH</div>
          <div class="lootbox-boxes-balance-item-price-total">= {{ price * amount }} METH</div>
        </div>
        <a
          href="#"
          class="lootbox-boxes-balance-item-button"
          :class="{ disabled: isOpening }"
          @click.prevent="$emit('open', amount)"
        >
          <slot name="action-text" :amount="amount">Open {{ amount }}x</slot>
        </a>
        <slot name="after-content" :amount="amount" />
      </div>
    </div>
    <div v-if="showTooltip" class="lootbox-boxes-balance-item-tooltip">
      <slot name="tooltip" />
    </div>
  </div>
</template>
<script setup>
import ACounter from "@/components/ACounter.vue";
import { computed, ref } from "vue-demi";

const props = defineProps({
  isOpening: Boolean,
  image: String,
  price: [String, Number],
  text: String,
  count: Number,
  showTooltip: Boolean
})
const emit = defineEmits(['open'])
const amount = ref(1)
const amountModel = computed({
  get() {
    return amount.value
  },
  set(val) {
    if (val > props.count) {
      amount.value = props.count
      return
    }
    amount.value = val

  }
})

</script>
<style lang="scss"></style>