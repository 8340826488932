import axios from "axios";

export const getEthUsd = async () => {
  try {
    const response = await axios.get(
      "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
    );

    return response.data.USD;
  } catch (error) {
    console.error(error);
    return null;
  }
};
