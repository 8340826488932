<template>
    <div class="lootbox-boxes">
        <div class="lootbox-boxes-alert">
            <IInfo width="14" />Loot Boxes are non refundable and do not qualify for the ETH back guarantee
        </div>
        <LootBoxBoxesRow
            :items="legendaryBoxes"
            type="legendary"
            name="Legendary box"
            scroll-button="View legendary loot box Scroll"
            :buying-item="buyingItem && buyingItem.type === 'legendary' ? buyingItem : null"
            @buy="onBuy('legendary', $event)"
            @openScroll="openBoxList('legendary')"
        />
        <LootBoxBoxesRow
            :items="epicBoxes"
            type="epic"
            name="Epic box"
            scroll-button="View epic loot box Scroll"
            :buying-item="buyingItem && buyingItem.type === 'epic' ? buyingItem : null"
            @buy="onBuy('epic', $event)"
            @buyCard="onBuyCard('epic', $event)"
            @openScroll="openBoxList('epic')"
        />

        <img class="lootbox-boxes-bg" src="@/assets/img/lootbox/lootbox-boxes-bg.svg" alt="bg" />
    </div>
</template>
<script>
import { ModalName } from "@/components/Modal/modal-name"
import { useModal } from "@/composables/useModal"
import { openLootBox } from "@/functions/lootbox/openLootBox"
import { computed, defineComponent, ref } from "vue"
import LootBoxBoxesRow from "./LootBoxBoxesRow.vue"
import IInfo from "@/assets/img/info.svg?inline"
import { useWeb3Store } from "@/store/web3"
import { connectWallet } from "@/web3"
import Web3 from "web3"
import { Config } from "@/config"
export default defineComponent({
    props: {
        epicBoxPrice: [String, Number],
        legendaryBoxPrice: [String, Number],
        ethPrice: Number,
        legendarySignatures: Object,
        epicSignatures: Object,

    },
    components: { LootBoxBoxesRow, IInfo },
    setup(props) {
        const web3Store = useWeb3Store()
        const getBoxPrice = (type, amount,) => {
            let price = type === 'legendary' ? props.legendaryBoxPrice : props.epicBoxPrice
            if (!price) return 'Loading...'
            price = price * amount

            // for amount 11 or more we add 25% discount
            // for amount 5 or more we add 10% discount
            if (Number(amount) >= 11) {
                price = price * 0.75
            } else if (Number(amount) >= 5) {
                price = price * 0.9
            }

            // price should not have decimals
            price = Math.round(price)

            return Number(Web3.utils.fromWei(price.toString())).toFixed(3) + ' ETH'
        }
        const epicBoxes = computed(() => [
            {
                price: `${getBoxPrice('epic', 1)} `,
                btn: '1 box',
                amount: 1,
                image: require('@/assets/img/lootbox/lootbox-epic.jpg')
            },
            {
                price: `${getBoxPrice('epic', 5)}`,
                btn: '5 boxes',
                amount: 5,

                image: require('@/assets/img/lootbox/lootbox-epic-5.jpg')
            },
            {
                price: `${getBoxPrice('epic', 11)}`,
                btn: '11 boxes',
                amount: 11,

                image: require('@/assets/img/lootbox/lootbox-epic-11.jpg')
            },
        ].map(item => {
            let price = parseFloat(item.price)
            let usdPrice;
            if (isNaN(price)) {
                usdPrice = item.price
            } else {
                usdPrice = price
                usdPrice *= props.ethPrice
                usdPrice = usdPrice.toFixed(2)
                usdPrice += ' USD'
            }

            return { ...item, usdPrice, crossmint: props.epicSignatures?.[item.amount] }
        }))
        const legendaryBoxes = computed(() => [
            {
                price: `${getBoxPrice('legendary', 1)} `,
                btn: '1 box',
                amount: 1,

                image: require('@/assets/img/lootbox/lootbox-legendary.jpg')
            },
            {
                price: `${getBoxPrice('legendary', 5)}`,
                btn: '5 boxes',
                amount: 5,

                image: require('@/assets/img/lootbox/lootbox-legendary-5.jpg')
            },
            {
                price: `${getBoxPrice('legendary', 11)}`,
                btn: '11 boxes',
                amount: 11,

                image: require('@/assets/img/lootbox/lootbox-legendary-11.jpg')
            },
        ].map(item => {
            let price = parseFloat(item.price)
            let usdPrice;
            if (isNaN(price)) {
                usdPrice = item.price
            } else {
                usdPrice = price
                usdPrice *= props.ethPrice
                usdPrice = usdPrice.toFixed(2)
                usdPrice += ' USD'
            }
            return { ...item, usdPrice, crossmint: props.legendarySignatures?.[item.amount]  }
        }))
        const isBuying = ref(false)
        const buyingItem = ref()
        const onBuy = async (type, box) => {
            if (isBuying.value) return
            if (!web3Store.isConnected) {
                const done = await connectWallet()
                if (!done) return
            }
            const { price, amount } = box
            isBuying.value = true
            buyingItem.value = { ...box, type }
            try {
                await openLootBox({ type, price, amount })
            } catch (err) {
                console.error(err)
            }
            isBuying.value = false
            buyingItem.value = null

        }


        const openBoxList = (type) => {
            useModal().open({ name: ModalName.LootBoxListModal, props: { type } })
        }
        return {
            buyingItem,
            isBuying,
            openBoxList,
            legendaryBoxes,
            epicBoxes, onBuy
        }
    }
})
</script>
<style lang="scss">
.lootbox-boxes {
    position: relative;
    &-alert {
        color: #fff;
        opacity: 0.5;
        font-weight: bold;
        margin-bottom: 15px;
        display: flex;

        svg {
            flex-shrink: 0;
            margin-right: 5px;
            height: 20px;
        }
        @media screen and (max-width: 576px) {
            font-size: 12px;
        }
    }
    &-bg {
        position: absolute;
        z-index: -1;
        width: 1100px;
        top: -60%;
        right: -30%;
    }
}
</style>