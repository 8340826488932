<template>
  <div class="lootbox-ape-info">
    <div class="lootbox-ape-info-image">
      <img
        src="@/assets/img/lootbox/lootbox-common.jpg"
        alt="container"
      >
    </div>
    <div class="lootbox-ape-info-name">
      Common Box
    </div>
    <div class="lootbox-ape-info-time">
      <IClock width="18" />
      <span v-if="canOpen && hasPower && !canOpenAgain">Available</span>
      <span v-else-if="canOpen && canOpenAgain">Available until {{ cooldownTimerText }}</span>
      <span v-else>Available in {{ powerTimerText }}</span>
    </div>

    <!-- <a
      href="#"
      class="lootbox-ape-info-action"
      :class="{ disabled: isDisabled }"
      @click.prevent="openBox"
    >Open Common Box</a>-->

    <div class="lootbox-ape-info-offchain">
      <LootBoxApeInfoCounter
        v-model:value="openAmount"
        :disabled="isDisabled"
      />
      <!-- <div class="lootbox-ape-info-price">{{ priceText }}</div> -->

      <a
        href="#"
        class="lootbox-ape-info-action lootbox-ape-info-offchain-action"
        :class="{ disabled: isDisabled || notEnoughMeth }"
        @click.prevent="openBoxOffChain"
      >{{ priceText }}</a>
      <div
        v-if="notEnoughMeth"
        class="lootbox-ape-info-error"
      >
        You don't have enough Methane in Meth Bank
      </div>
      <div class="lootbox-ape-info-offchain-tooltip">
        We use Methane from Meth Bank
      </div>
    </div>
    <LootBoxApeInfoOdd :value="ape.lootBox.booster" />
    <a
      href="#"
      class="lootbox-ape-info-list"
      @click.prevent="openCommonScroll"
    >
      <IList width="16" />VIEW COMMON LOOT BOX SCROLL
    </a>
  </div>
</template>
<script>
import { computed, defineComponent, ref, watch } from "vue"
import { useTimer } from "vue-timer-hook"
import IClock from "@/assets/icons/ape-constructor/ape-constructor-clock.svg?inline"
import moment from "moment"
import { useWalletCollectionStore } from "@/store/wallet-collection"
import { openCommonLootBox } from "@/functions/lootbox/openCommonLootBox"
import { useApesStorageApi } from "@/api/useApesStorageApi"
import { useWeb3Store } from "@/store/web3"
import { useModal } from "@/composables/useModal"
import { ModalName } from "@/components/Modal/modal-name"
import IList from "@/assets/icons/ape-constructor/ape-constructor-lootbox-scroll.svg?inline"
import { openCommonLootBoxOffChain } from "@/functions/lootbox/openCommonLootBoxOffChain"
import LootBoxApeInfoCounter from "./LootBoxApeInfoCounter.vue"
import { formatNumberSpaces } from "@/helpers/format-number-spaces"
import { useMethBankStore } from "@/store/meth-bank"
import { getApeLootBoxPrice } from "@/helpers/get-ape-lootbox-price"
import LootBoxApeInfoOdd from "./LootBoxApeInfoOdd.vue"

export default defineComponent({
  components: { IClock, IList, LootBoxApeInfoOdd, LootBoxApeInfoCounter },
  props: {
    ape: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const store = useWalletCollectionStore()
    const freeSpinsCount = computed(() => props.ape.freeSpinsCount)
    const price = computed(() => freeSpinsCount.value > 0 ? 0 : props.ape.lootBox?.price)

    const cooldownTimer = useTimer()
    const powerTimer = useTimer()
    const isOpening = ref(false)
    const hasPower = computed(() => props.ape.power >= 100)
    const lastBoxOpened = computed(() => props.ape.lootBox?.lastBoxOpened)
    const methBalance = computed(() => useMethBankStore().balance)
    const openAmount = ref(1)
    const totalPrice = computed(() => {
      return getApeLootBoxPrice(props.ape, openAmount.value)
    })
    const notEnoughMeth = computed(() => methBalance.value < totalPrice.value)

    const priceText = computed(() => {
      if (freeSpinsCount.value > 0 && openAmount.value <= freeSpinsCount.value) {
        return `Free ${freeSpinsCount.value > 1 ? freeSpinsCount.value + 'x' : ''}`
      }
      return `Open ${openAmount.value} Box${openAmount.value > 1 ? 'es' : ''} for ${formatNumberSpaces(totalPrice.value)} Methane`
    })
    const canOpenWithPower = computed(() => {
      return hasPower.value
    })
    const canOpenAgain = computed(() => {
      if (!props.ape?.lootBox?.openUntil) return false
      const openUntil = moment(props.ape?.lootBox?.openUntil)
      if (moment().diff(openUntil, 'seconds') < 0) return true
      return false
    })
    const canOpen = computed(() => {
      if (canOpenWithPower.value) {
        return true
      }
      if (canOpenAgain.value) {
        return !cooldownTimer.isExpired.value
      }
      return powerTimer.isExpired.value
    })
    const isDisabled = computed(() => !canOpen.value || isOpening.value)
    const cooldownTimerText = computed(() => {
      const { seconds, minutes } = cooldownTimer
      return `${minutes.value < 10 ? "0" + minutes.value : minutes.value}:${seconds.value < 10 ? "0" + seconds.value : seconds.value}`;
    })
    const powerTimerText = computed(() => {
      const { hours, days } = powerTimer

      return `${days.value}d ${hours.value < 10 ? "0" + hours.value : hours.value}h`;
    })

    const checkCoolDown = () => {
      if (canOpenAgain.value) {
        // 80 seconds cool down
        cooldownTimer.restart(props.ape.lootBox.openUntil)
      }
    }
    const checkPower = async () => {
      if (hasPower.value) return
      // no power
      const timePower100 = moment(props.ape.powerInfo.fullPowerDate).add(1, 'minutes')
      powerTimer.restart(timePower100.toDate())
    }
    watch(() => props.ape?.lootBox?.openUntil, checkCoolDown)
    watch(lastBoxOpened, checkCoolDown)
    watch(hasPower, checkCoolDown)
    watch(cooldownTimer.isExpired, checkPower)
    checkCoolDown()
    checkPower()
    const openBox = async () => {
      if (isDisabled.value || notEnoughMeth.value) return

      isOpening.value = true
      try {
        await openCommonLootBox({ apeId: props.ape.id })

      } catch (err) {
        console.error(err)
      }
      isOpening.value = false
    }
    const openBoxOffChain = async () => {
      if (isDisabled.value || notEnoughMeth.value) return

      isOpening.value = true
      try {
        await openCommonLootBoxOffChain({ apeId: props.ape.id, amount: openAmount.value })

      } catch (err) {
        console.error(err)
      }
      isOpening.value = false
    }
    const openCommonScroll = () => {
      useModal().open({ name: ModalName.LootBoxListModal, props: { type: 'common' } })
    }

    return {
      notEnoughMeth,
      openAmount,
      openBoxOffChain,
      openCommonScroll,
      canOpenAgain,
      hasPower,
      cooldownTimerText,
      powerTimerText,
      openBox,
      freeSpinsCount,
      canOpen,

      priceText,

      isDisabled
    }
  }
})
</script>
<style lang="scss">
.lootbox-ape-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  &-image {
    margin-bottom: 4px;
    img {
      max-width: 100%;
      height: auto;
      max-width: 200px;
    }
    /*@media screen and (max-width: 992px) {
      display: none;
    }*/
  }
  &-error {
    color: var(--red);
    padding: 4px 0;
    font-size: 14px;
    text-align: center;
  }
  &-offchain {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-action {
      width: 100%;
      display: block;
    }
    &-counter {
    }
    &-tooltip {
      font-size: 14px;
      opacity: 0.5;
      text-align: center;
      margin-top: 5px;
    }
    &-del {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
      &::before {
        content: "";
        display: block;
        height: 1px;
        background-color: var(--gray-light);
        flex: 1;
        margin-right: 10px;
      }
      &::after {
        content: "";
        display: block;
        height: 1px;
        background-color: var(--gray-light);
        flex: 1;
        margin-left: 10px;
      }
    }
  }
  &-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }
  &-list {
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
    color: #fff;
    opacity: 0.8;
    transition: 0.2s;
    &:hover {
      opacity: 1;
    }
    svg {
      margin-right: 5px;
    }
  }
  &-name {
    font-size: 18px;
    margin-bottom: 8px;
  }
  &-price {
    font-size: 18px;
    margin-bottom: 4px;
    color: var(--yellow);
  }

  &-time {
    display: flex;
    align-items: center;
    color: #828282;
    svg {
      margin-right: 8px;
    }
  }
  &-action {
    padding: 8px;
    background-color: var(--primary);
    color: #fff;
    text-decoration: none;
    width: 100%;
    text-align: center;
    margin-top: 8px;
    border-radius: 5px;
    box-sizing: border-box;
    transition: 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
    &.disabled {
      color: #828282;
      background: #1e1e1e;
      cursor: default;
      &:hover {
        filter: none;
      }
    }
  }
  @media screen and (max-width: 992px) {
    &-loading {
      min-height: 0;
    }
  }
}
</style>