<template>
  <div class="lootbox-boxes-balance">
    <div class="lootbox-boxes-balance-items">
      <LootBoxBoxesBalanceItem
        v-if="boxes.common > 0"
        :count="boxes.common"
        class="lootbox-boxes-balance-common"
        :image="require('@/assets/img/lootbox/lootbox-common.jpg')"
        text="Free Box"
        :is-opening="isOpening"
        :show-tooltip="true"
        @open="openCommonBox"
      >
        <template #tooltip>
          More info about the 4 free boxes
          <IInfo v-tooltip="tooltipContent" width="14" />
        </template>
      </LootBoxBoxesBalanceItem>
      <LootBoxBoxesBalanceItem
        v-if="boxes.epic > 0"
        :count="boxes.epic"
        :image="require('@/assets/img/lootbox/lootbox-epic.jpg')"
        class="lootbox-boxes-balance-epic"
        text="epic box"
        :is-opening="isOpening"
        :show-tooltip="true"
        @open="openBox('epic', $event)"
      >
        <template #after-content>
          <div class="lootbox-boxes-balance-item-sub-tooltip">Already Paid</div>
        </template>
        <template #tooltip>Available to open</template>
      </LootBoxBoxesBalanceItem>
      <LootBoxBoxesBalanceItem
        v-if="boxes.legendary > 0"
        :count="boxes.legendary"
        :image="require('@/assets/img/lootbox/lootbox-legendary.jpg')"
        class="lootbox-boxes-balance-legendary"
        text="legendary box"
        :is-opening="isOpening"
        :show-tooltip="true"
        @open="openBox('legendary', $event)"
      >
        <template #after-content>
          <div class="lootbox-boxes-balance-item-sub-tooltip">Already Paid</div>
        </template>
        <template #tooltip>Available to open</template>
      </LootBoxBoxesBalanceItem>
    </div>
  </div>
</template>
<script setup>
import { openCommonLootBoxOffChain } from "@/functions/lootbox/openCommonLootBoxOffChain";
import { openLootBoxOffChain } from "@/functions/lootbox/openLootBoxOffchain";
import { useWeb3Store } from "@/store/web3";
import { computed, ref } from "vue-demi";
import LootBoxBoxesBalanceItem from "./LootBoxBoxesBalanceItem.vue";
import IInfo from "@/assets/img/info.svg?inline"

const props = defineProps({ boxes: Object })

const emit = defineEmits(['update:balance'])
const isOpening = ref(false)


const openCommonBox = async (amount) => {
  if (isOpening.value) return
  isOpening.value = true
  await openCommonLootBoxOffChain({ apeId: 0, amount: amount, })
  isOpening.value = false
  emit('update:balance')
}
const openBox = async (type, amount) => {
  if (isOpening.value) return
  isOpening.value = true
  await openLootBoxOffChain({ apeId: 0, type, amount: amount, })
  isOpening.value = false
  emit('update:balance')
}
const tooltipContent = {
  content: `4x free loot box spins have been released for both snapshots`,
  distance: 10
}
</script>
<style lang="scss">
.lootbox-boxes-balance {
  margin-top: 20px;
  margin-bottom: 30px;
  &-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &-item {
    text-transform: uppercase;
    box-sizing: border-box;

    border: 1px solid var(--border);
    box-shadow: 0 0 10px 1px var(--rarity-common);
    padding: 10px 15px;
    border-radius: 5px;
    margin: 10px;
    min-width: 310px;

    &-row {
      display: flex;
      align-items: center;
    }
    &-image {
      margin-right: 8px;
      overflow: hidden;
      img {
        transform: scale(1.7) translateY(-5px);
        display: block;
      }
    }
    &-price {
      margin-bottom: 4px;
      text-align: center;
      &-wrapper {
        margin-bottom: 4px;
        margin-top: -4px;
      }
      &-cross {
        text-align: center;
        opacity: 0.5;
        margin-bottom: 3px;
      }
      &-total {
        color: var(--yellow);
      }
    }
    &-tooltip {
      margin-top: 5px;
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-left: 5px;
        transform: translateY(-2px);
        color: rgba(255, 255, 255, 0.8);
      }
    }
    &-sub-tooltip {
      font-size: 12px;
      opacity: 0.5;
      text-transform: uppercase;
      margin-top: 3px;
    }
    &-text {
      margin-bottom: 5px;
      text-align: center;
    }
    &-content {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-counter {
      padding: 3px 0;
      border-radius: 5px;
      margin-bottom: 5px;
    }
    &-button {
      background-color: var(--primary);
      border-radius: 5px;
      color: #fff;
      text-decoration: none;
      padding: 8px;
      width: 120px;
      text-align: center;
      transition: 0.2s;
      box-sizing: border-box;
      display: inline-block;
      &:hover {
        filter: brightness(0.8);
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  &-epic {
    box-shadow: 0 0 10px 1px var(--rarity-epic);
  }
  &-legendary {
    box-shadow: 0 0 10px 1px var(--rarity-legendary);
  }
  &-common {
    .lootbox-boxes-balance-item-image {
      img {
        transform: none;
      }
    }
  }
  @media screen and (max-width: 992px) {
    margin-top: 40px;
    &-items {
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (max-width: 576px) {
    &-items {
      margin-left: 0;
      margin-right: 0;
    }
    &-item {
      min-width: 0;
      width: 100%;
      margin: 10px 0;
    }
  }
}
</style>