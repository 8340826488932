<template>
  <div class="lootbox-ape">
    <h2 class="lootbox-ape-title">
      Your Apes
    </h2>
    <div class="lootbox-ape-row">
      <div class="lootbox-ape-apes">
        <LootBoxApeSlider
          v-if="isConnected && items.length"
          v-model:apeId="activeApeId"
          :items="items"
        />
        <div
          v-else-if="isLoading"
          class="lootbox-ape-loading"
        >
          <img
            src="@/assets/img/spinner-clear.svg"
            class="spinner"
            width="50"
          >
        </div>
        <div
          v-else-if="!isConnected"
          class="lootbox-ape-connect"
        >
          <div>Connect the Wallet to see your apes</div>

          <button
            class="lootbox-ape-connect-button"
            @click.prevent="connect"
          >
            Connect
          </button>
        </div>
        <div
          v-else
          class="lootbox-ape-empty"
        >
          <div>You don't own any apes yet</div>
          <div class="lootbox-ape-empty-buy">
            You can buy them here
            <a
              href="https://opensea.io/collection/bullsandapes-apes"
              target="_blank"
            >Here</a>
          </div>
        </div>
      </div>
      <Transition
        name="t-lootbox-ape"
        mode="out-in"
      >
        <LootBoxApeInfo
          v-if="items.length > 0 && activeApeId"
          :key="activeApeId"
          :ape="activeApe"
        />
      </Transition>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, ref, watch } from "vue"
import LootBoxApeSlider from "@/components/LootBox/LootBoxApe/LootBoxApeSlider.vue"
import LootBoxApeInfo from "./LootBoxApeInfo.vue"
import { useRoute } from "vue-router"
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { captureError } from "@/helpers/captureError";
import { openCommonLootBox } from "@/functions/lootbox/openCommonLootBox";
import { useWeb3Store } from "@/store/web3";
import { connectWallet } from "@/web3";
export default defineComponent({
  components: { LootBoxApeSlider, LootBoxApeInfo },
  setup() {
    const route = useRoute()
    const store = useWalletCollectionStore()
    const items = computed(() => store.allApes)
    const web3Store = useWeb3Store()
    const apeId = route.query.ape
    const activeApeId = ref(+apeId || undefined)
    const activeApe = computed(() => items.value.find(ape => ape.id === activeApeId.value))
    const isConnected = computed(() => web3Store.isConnected)
    const isLoading = ref(false)
    const init = async () => {
      try {
        if (!isConnected.value) {
          store.allApes = []
          return
        }
        if (!store.allApes.length) {
          isLoading.value = true
          await store.fetchApes()
          isLoading.value = false
        }
        if (!activeApeId.value) {
          activeApeId.value = items.value?.[0]?.id
        }
      } catch (err) {
        captureError(err)
      }
    }
    init()
    watch(() => web3Store.walletAddress, () => {
      init()
    })
    const connect = () => {
      connectWallet()
    }
    return { activeApeId, isLoading, activeApe, items, connect, isConnected }
  }
})
</script>
<style lang="scss">
.lootbox-ape {
  max-width: 480px;
  width: 100%;
  &-title {
    text-align: center;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
  }
  &-empty {
    text-align: center;
    text-transform: uppercase;
    a {
      color: var(--primary);
    }
    &-buy {
      padding-top: 10px;
      border-top: 1px solid var(--border);
      margin-top: 10px;
    }
  }
  &-connect {
    text-align: center;
    &-button {
      margin-top: 10px;
      padding: 10px 30px;
      background-color: var(--primary);
      border-radius: 5px;
      color: #fff;
      border: none;
      cursor: pointer;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &-loading {
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 1350px) {
    max-width: 350px;
    &-row {
      display: flex;
      flex-direction: column;
    }
    &-apes {
      order: 2;
      margin-top: 15px;
    }
  }
  @media screen and (max-width: 576px) {
    max-width: 300px;

    &-title {
      margin-bottom: 10px;
    }
  }
}
.t-lootbox-ape {
  &-enter-from {
    opacity: 0;
    transform: scale(0.3);
  }
  &-leave-to {
    opacity: 0;
  }
  &-enter-active,
  &-leave-active {
    transition: 0.3s;
  }
}
</style>