<template>
  <div class="lootbox-header">
    <div class="lootbox-header-row">
      <div class="lootbox-header-back button-back" @click.prevent="goBack">
        <IArrowLeft width="32" />
        <span>Back</span>
      </div>
      <FormulasButton class="lootbox-header-button" />
      <TraitShopButton class="lootbox-header-button" />
    </div>
    <div>
      <h2 class="lootbox-header-title">Welcome to the Loot Box Store</h2>
      <div class="lootbox-header-description">Open Loot Boxes and mint new traits!</div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue-demi";
import { useRouter } from "vue-router";
import IArrowLeft from "@/assets/icons/arrow-left-long.svg?inline"
import TraitShopButton from "../TraitShop/TraitShopButton.vue";
import FormulasButton from "../Formulas/FormulasButton.vue";
export default defineComponent({
  components: { IArrowLeft, FormulasButton, TraitShopButton },
  setup() {
    const router = useRouter()
    const goBack = () => {
      const lastPath = router.options.history.state.back
      if (lastPath) {
        router.back()
      } else {
        router.push({ name: "Collection" })
      }
    }
    return { goBack }
  }
})
</script>
<style lang="scss">
.lootbox-header {
  padding-bottom: 20px;
  margin: 0 auto;
  border-bottom: 1px solid var(--ape-constructor-gray);
  margin-bottom: 45px;
  max-width: 1400px;
  &-back {
    display: inline-flex;
    margin-right: auto;
  }

  &-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &-title {
    font-size: 32px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
  }
  &-description {
    font-size: 18px;
    text-transform: uppercase;
    color: #7e7e7e;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 5px;
    padding-bottom: 10px;
    &-back {
      font-size: 14px;
      padding: 4px 15px;
    }
    &-button {
      margin-top: 10px;
    }
    &-row {
      flex-direction: column;
      align-items: flex-start;
    }
    &-title {
      font-size: 18px;
      margin-bottom: 5px;
    }
    &-description {
      font-size: 14px;
    }
  }
}
</style>