<template>
  <div class="lootbox-ape-info-counter-wrapper">
    <div class="lootbox-ape-info-counter-title">
      Boxes Count
    </div>
    <div
      class="lootbox-ape-info-counter"
      :class="{ disabled }"
    >
      <a
        href="#"
        class="lootbox-ape-info-counter-button"
        @click.prevent="doMinus"
      >
        <IMinus width="21" />
      </a>
      <div class="lootbox-ape-info-counter-value">
        {{ value }}
      </div>
      <a
        href="#"
        class="lootbox-ape-info-counter-button"
        @click.prevent="doPlus"
      >
        <IPlus width="20" />
      </a>
    </div>
  </div>
</template>
<script setup>
import { computed, defineComponent } from "vue"
import IPlus from "@/assets/icons/plus.svg?inline"
import IMinus from "@/assets/icons/minus-stroke.svg?inline"
const props = defineProps({ value: Number, disabled: Boolean });
const emit = defineEmits(['update:value']);
const valueModel = computed({
    get() {
        return props.value
    },
    set(val) {
        emitValue(val)
    }
})
const emitValue = (val) => {
    const nVal = parseInt(val)
    if (!nVal || nVal < 1) return
    emit('update:value', nVal)
}
const onChange = (val) => {
    const nVal = parseInt(val)
    if (!nVal || nVal < 1) {
        emit('update:value', 1)
        return
    }
    emitValue(val)
}
const doMinus = () => {
    let newValue = props.value - 1
    if (newValue < 1) newValue = 1
    emit('update:value', newValue)
}
const doPlus = () => {
    const newValue = props.value + 1
    emit('update:value', newValue)
}
</script>
<style lang="scss">
.lootbox-ape-info-counter {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--gray-light);
    border-radius: 5px;
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &-wrapper {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 5px;

    }
    &-title {
        opacity: 0.5;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 3px;
    }
    &-value {
        margin: 0 10px;
        line-height: 1;
        min-width: 20px;
    }
    &-button {
        color: #fff;
        user-select: none;
        display: block;
        font-size: 0;
        &:hover {
            color: var(--primary);
        }
    }
}
</style>