<template>
    <div class="lootbox-meth-boxes" v-if="boxes.epic > 0 || boxes.legendary > 0">
        <div class="lootbox-meth-boxes-title">
            Loot Boxes for METH
            <!-- <img width="20" src="@/assets/img/meth.png" alt="meth" /> -->
        </div>
        <div class="lootbox-meth-boxes-items">
            <LootBoxBoxesBalanceItem
                v-if="boxes.epic > 0"
                :count="boxes.epic"
                :price="epicPrice"
                :image="require('@/assets/img/lootbox/lootbox-epic.jpg')"
                class="lootbox-meth-boxes-epic lootbox-boxes-balance-item-epic"
                text="epic"
                :is-opening="isOpening"
                :show-tooltip="true"
                @open="openBox('epic', $event)"
            ></LootBoxBoxesBalanceItem>
            <LootBoxBoxesBalanceItem
                v-if="boxes.legendary > 0"
                :count="boxes.legendary"
                :price="legendaryPrice"
                :image="require('@/assets/img/lootbox/lootbox-legendary.jpg')"
                class="lootbox-meth-boxes-legendary lootbox-boxes-balance-item-legendary"
                text="legendary"
                :is-opening="isOpening"
                :show-tooltip="true"
                @open="openBox('legendary', $event)"
            ></LootBoxBoxesBalanceItem>
        </div>
    </div>
</template>
<script setup>
import { openLootBoxMeth } from "@/functions/lootbox/buyLootBoxMeth"
import LootBoxBoxesBalanceItem from "../LootBoxBoxesBalance/LootBoxBoxesBalanceItem.vue"
import { ref } from "vue"
import { useMethBankStore } from "@/store/meth-bank"
const props = defineProps({ boxes: Object, epicPrice: Number, legendaryPrice: Number })

const emit = defineEmits(['update:balance'])
const isOpening = ref(false)

const openBox = async (type, amount) => {
    if (isOpening.value) return
    isOpening.value = true
    await openLootBoxMeth({ type, amount: amount, })
    isOpening.value = false
    useMethBankStore().fetchMethBank()
    emit('update:balance')
}
</script>
<style lang="scss">
.lootbox-meth-boxes {
    margin-bottom: 24px;
    position: relative;
    &-title {
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;

        background-color: var(--yellow);
        padding: 8px;
        text-transform: uppercase;

    }
    &-items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        z-index: 5;
    }
    &-bg {
        position: absolute;
        left: 50%;
        top: 40px;
        transform: translateX(-50%);
        z-index: 0;
        width: 200px;
        opacity: 0.3;
        filter: blur(4px);
    }
}
</style>