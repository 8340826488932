import { useApesStorageApi } from "@/api/useApesStorageApi";
import { ModalName } from "@/components/Modal/modal-name";
import { useContract } from "@/composables/useContract";
import { useModal } from "@/composables/useModal";
import { useToast } from "@/composables/useToast";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { resizeImageCF } from "@/helpers/resize-image-cloudflare";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { openLootBoxOffChain } from "./openLootBoxOffchain";

const abi = [
  {
    inputs: [
      { internalType: "uint256", name: "amount", type: "uint256" },
      { internalType: "uint256", name: "boxType", type: "uint256" },
      { internalType: "uint256", name: "price", type: "uint256" },
      { internalType: "uint256", name: "timeOut", type: "uint256" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "bytes", name: "signature", type: "bytes" },
    ],
    name: "buyLootbox",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
];

const contractAddress =
  Config.network.name === "sepolia"
    ? "0xf654c8811e38c25544975224705Ae32FeF44815B"
    : "0x8C0A340dDd36CF89E65f5Cb7C5C273285b45Bef8";

const useContractOpenBox = useContract(abi, contractAddress);
export const openLootBox = async ({ type, amount }) => {
  try {
  //   const itms = [
  //     {
  //       id: 105,
  //       image:
  //         "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/a1091d8b-0ba2-4f85-fd1a-b74ddf43a600/w=256",

  //       rarity: "common",
  //       stars: 3,
  //     },
  //     {
  //       id: 525,
  //       image:
  //         "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/df31f8ad-06bf-4fd4-f4c6-05a5aa3dd500/w=256",
  //       rarity: "common",
  //       categoryName: "Clothing",
  //       stars: 5,
  //       name: "U-Skittles Fur"
  //     },
  //     {
  //       id: 804,
  //       image:
  //         "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/4b1874ea-c0c3-44b1-e983-48bf207af400/w=256",
  //       rarity: "common",
  //       stars: 2,
  //     },
  //     {
  //       id: 885,
  //       image:
  //         "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/51c11073-6973-48d0-a699-181040985300/w=256",
  //       rarity: "common",
  //     },
  //     {
  //       id: 893,
  //       image:
  //         "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/35a11441-9695-478c-d39f-b759b856d800/w=256",
  //       rarity: "common",
  //     },
  //   ];
  //   useModal().open({
  //     name: ModalName.LootBoxOpenModal,
  //     props: {
  //       type: "common",
  //       winningIds: [525, 893, 804],
  //       isNewTraits: [true, false],
  //       apeId: 30006,
  //       items: itms,
  //       methAmounts: [100, 50, 0]
  //     },
  //   });
  //   return;
    const wallet = useWeb3Store().walletAddress;
    const data = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/lootBox/buySpecial",
      params: {
        boxType: type,
        recipient: wallet,
        amount,
        network: Config.network.name,
      },
    });
    if (!data) throw new Error("Couldn't sign lootbox transaction");
    const { timeOut, signature, price } = data;
    const contract = useContractOpenBox(true);

    const result = await useToastTransaction(
      contract.methods.buyLootbox(
        amount,
        type === "epic" ? 1 : 2,
        price,
        timeOut,
        wallet,
        signature
      ),
      {
        text: `Buying ${amount} ${
          type.charAt(0).toUpperCase() + type.slice(1)
        } Loot Box${amount > 1 ? "es" : ""}`,
        sendOptions: {
          value: price,
        },
      }
    );
    await openLootBoxOffChain({ apeId: 0, type, amount });
  } catch (err) {
    // useToast().error({ title: "Couldn't open Loot Box, please try again" });
    captureError(err);
  }
};
