<template>
    <div class="lootbox-page">
        <LootBoxHeader />

        <div :key="walletAddress" class="lootbox-page-row">
            <div class="lootbox-page-left">
                <LootBoxApe />
            </div>
            <div class="lootbox-page-right">
                <LootBoxBalance />
                <LootBoxBoxesBalance :boxes="freeBoxesCount" @update:balance="fetchFreeBoxesCount" />
                <LootBoxMethBoxes
                    :boxes="methBoxesCount"
                    :legendaryPrice="lootboxPrices.legendaryMeth"
                    :epicPrice="lootboxPrices.epicMeth"
                    @update:balance="fetchFreeBoxesCount"
                />

                <LootBoxBoxes
                    :epic-box-price="lootboxPrices.epic"
                    :legendary-box-price="lootboxPrices.legendary"
                    :ethPrice="lootboxPrices.ethPrice"
                    :epicSignatures="lootboxPrices.epicSignatures"
                    :legendarySignatures="lootboxPrices.legendarySignatures"
                />
                <LootBoxStats />
            </div>
        </div>
        <!-- <div class="lootbox-page-connect">
            <div class="lootbox-page-connect-title">Please connect the wallet</div>
            <a
                href="javascript:void(0)"
                class="lootbox-page-connect-button"
                @click.prevent="connect"
            >Connect wallet</a>
        </div>-->
    </div>
</template>
<script>import { computed, defineComponent, ref, watch } from "vue-demi";
import LootBoxHeader from "../components/LootBox/LootBoxHeader.vue";
import LootBoxApe from "../components/LootBox/LootBoxApe/LootBoxApe.vue";
import LootBoxBoxes from "@/components/LootBox/LootBoxBoxes/LootBoxBoxes.vue";
import { useWeb3Store } from "@/store/web3";
import { connectWallet, inited } from "@/web3";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
import LootBoxBalance from "@/components/LootBox/LootBoxBalance/LootBoxBalance.vue";
import { useToast } from "@/composables/useToast";
import { watchOnce } from "@vueuse/shared";
import { useRouter } from "vue-router";
import { usePageWithWallet } from "@/composables/usePageWithWallet";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useMethBankStore } from "@/store/meth-bank";
import LootBoxBoxesBalance from "@/components/LootBox/LootBoxBoxesBalance/LootBoxBoxesBalance.vue";
import { useApesStorageApi } from "@/api/useApesStorageApi";
import LootBoxStats from "@/components/LootBox/LootBoxStats.vue";
import LootBoxMethBoxes from "@/components/LootBox/LootBoxMethBoxes/LootBoxMethBoxes.vue";
import { getEthUsd } from "@/functions/getEthUsd";

export default defineComponent({
    components: { LootBoxHeader, LootBoxMethBoxes, LootBoxBoxesBalance, LootBoxStats, LootBoxApe, LootBoxBoxes, LootBoxBalance },
    setup() {
        const freeBoxesCount = ref({})
        const methBoxesCount = ref({})
        const lootboxPrices = ref({})
        const fetchFreeBoxesCount = async () => {
            const data = await useApesStorageApi({ toast: { error: true } }).exec({
                url: "/lootbox/wallet-price",
                params: {
                    wallet: useWeb3Store().collectionWallet,
                },
            })
            const freeBoxesData = data[0]
            methBoxesCount.value = freeBoxesData.allowances
            freeBoxesCount.value = {
                common: freeBoxesData.common,
                epic: freeBoxesData.epic,
                legendary: freeBoxesData.legendary
            }
        }
        const fetchBoxPrices = async () => {
            const pricesData = await useApesStorageApi({ toast: { error: "Couldn't load lootbox prices" }, throw: true }).exec({
                url: "/lootBox/specialPrice",
                params: {
                    wallet: useWeb3Store().walletAddress
                }
            })
            const ethPrice = await getEthUsd()

            const { epicPrice, legendaryPrice, legendaryMeth, epicMeth, epicSignatures, legendarySignatures } = pricesData
            lootboxPrices.value = {
                epic: epicPrice,
                legendary: legendaryPrice,
                epicMeth: epicMeth,
                legendaryMeth: legendaryMeth,
                ethPrice,
                epicSignatures,
                legendarySignatures,
            }

        }
        const { isConnected, connect } = usePageWithWallet({
            onInit(connected) {
                if (connected) {
                    useMethBankStore().fetchMethBank()
                    fetchFreeBoxesCount()
                    fetchBoxPrices()
                }
            },
            onWalletChange: () => {
                useWalletCollectionStore().fetchApes()
                useMethBankStore().fetchMethBank()
                fetchFreeBoxesCount()
            }
        })
        const walletAddress = computed(() => useWeb3Store().walletAddress)


        return { isConnected, connect, methBoxesCount, lootboxPrices, fetchFreeBoxesCount, freeBoxesCount, walletAddress, }
    }
})
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,700&family=Oxanium:wght@700;800&display=swap");

.lootbox-page {
    font-family: var(--font-oxanium);
    font-weight: bold;
    padding: 0 40px;
    padding-top: 20px;
    box-sizing: border-box;
    background-color: #000;
    flex: 1;
    overflow: hidden;
    &-row {
        display: flex;
        align-items: flex-start;
        max-width: 1400px;
        margin: 0 auto;
    }
    &-left {
        width: 350px;
        flex-shrink: 0;
        @media screen and (max-width: 1350px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 80px;
        }
    }
    &-right {
        padding-left: 150px;
        max-width: 100%;
        min-width: 0;
        @media screen and (max-width: 1450px) {
            padding-left: 50px;
        }
    }
    &-connect {
        display: flex;
        align-items: center;
        flex-direction: column;
        &-title {
            font-size: 24px;
            margin-bottom: 15px;
        }
        &-button {
            width: 100%;
            box-sizing: border-box;
            max-width: 300px;
            text-align: center;
            font-size: 18px;
            padding: 15px 30px;
            background-color: var(--primary);
            border-radius: 5px;
            color: #fff;
            text-decoration: none;
            transition: 0.2s;
            &:hover {
                filter: brightness(0.8);
            }
        }
    }
    @media screen and (max-width: 1350px) {
        &-row {
            flex-direction: column;
            align-items: center;
        }
        &-right {
            order: 1;
            padding-left: 0;
            width: 100%;
        }
        &-left {
            order: 2;
        }
    }

    @media screen and (max-width: 576px) {
        padding: 0 15px;
        padding-top: 20px;
        &-title {
            margin-bottom: 10px;
        }
    }
}
</style>