<template>
    <div class="lootbox-stats">
        <div class="lootbox-stats-title">Loot boxes opened</div>
        <div class="lootbox-stats-items">
            <div class="lootbox-stats-item lootbox-stats-item-common">
                <span>Common</span>
                <span>{{ formatNumberSpaces(stats.common || 0) }}</span>
            </div>
            <div class="lootbox-stats-item lootbox-stats-item-epic">
                <span>Epic</span>
                <span>{{ formatNumberSpaces(stats.epic || 0) }}</span>
            </div>
            <div class="lootbox-stats-item lootbox-stats-item-legendary">
                <span>Legendary</span>
                <span>{{ formatNumberSpaces(stats.legendary || 0) }}</span>
            </div>
        </div>
    </div>
</template>
<script setup>import { useApesStorageApi } from "@/api/useApesStorageApi";
import { Config } from "@/config";
import { formatNumberSpaces } from "@/helpers/format-number-spaces";
import { ref } from "vue-demi";
const stats = ref({})

const fetchData = async () => {
    stats.value = await useApesStorageApi({ toast: { error: () => "Couldn't load lootbox statistics data" } })
        .exec({ url: "/info/lootboxes-count", params: { network: Config.network.name } })
}
fetchData()
</script>
<style lang="scss">
.lootbox-stats {
    padding: 15px 0;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    &-title {
        text-align: center;
        margin-bottom: 10px;
        font-size: 18px;
        text-transform: uppercase;
    }
    &-items {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-item {
        opacity: 0.8;
        padding: 0 10px;
        display: flex;
        span {
            &:first-child {
                text-transform: uppercase;
                margin-right: 5px;
            }
        }
        &-common {
            color: rgba(255, 255, 255, 0.8);
        }
        &-epic {
            color: var(--rarity-epic);
        }
        &-legendary {
            color: var(--rarity-legendary);
        }
        span {
        }
    }
    @media screen and (max-width: 1350px) {
        margin-bottom: 20px;
    }
    @media screen and (max-width: 380px) {
        font-size: 14px;

        &-items {
            flex-wrap: wrap;
            justify-content: space-around;
        }
        &-item {
            padding: 0 10px;
            padding-top: 5px;
        }
    }
}
</style>